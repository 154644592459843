@import "variables";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "bulma/versions/bulma-no-dark-mode.scss";


.spinner-container {
    display: flex;
    justify-content: center;
    height: 200px;
    align-content: center;
    flex-wrap: wrap;
  
    .spinner-icon {
      color: var(--primary-dark);
      font-size: 2.5rem;
    }
}

.modal {
    z-index: 91;
}

.table {
    margin-bottom: 10px !important;

    thead {
        th {
            border: none;
            color: white;
            cursor: pointer;
        }

        th:first-child {
            border-top-left-radius: 10px;
        }

        th:last-child {
            border-top-right-radius: 10px;
        }

        background-color: var(--primary);
    }

    tbody tr:not(.is-selected,.is-pair-row,.is-unpair-row):nth-child(even) {
        background-color: var(--grey-lighter) !important;
    }

    th, td {
        text-align: center !important;
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: 10px;
        }

        td:last-child {
            border-bottom-right-radius: 10px;
        }
    }

    td {
        border: 1px solid hsl(0, 0%, 86%);
    }

    tr {
        td:first-child {
            border-left: none;
        }

        td:last-child {
            border-right: none;
        }
    }
}

.p-calendar {
    width: 100%;
}

.p-highlight {
    color: var(--primary);
}

.p-dropdown {
    width: 100% ;
    height: 45px;
    align-items: center;
    margin: 0;
    padding: 0;
    &:hover {
      border-color: var(--primary-light);
    } 
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #5cbc9c1a;
      border-color: var(--primary-light);
    }
}

.p-dropdown-item	{
    padding: 5px;
}

.p-dropdown.p-variant-filled {
    background-color: #5cbc9c1a;
    border: 1px solid var(--primary-light);
}

.p-inputtext {
    width: 100%;
}

.is-primary {
    background-color: var(--primary) !important;
    color: white !important;
}

.p-accordion-header-link {
    color: var(--primary) !important;
}

.p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 16px;
}

.tabs li.is-active a {
    border-bottom-color: var(--primary);;
    color: var(--primary);
}

.has-text-primary {
    color: var(--primary) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: var(--primary);
}

.p-multiselect-item {
    padding: 8px;
}