$primary: #007bc2;
$grey: #9C9C9C;
$grey-border: #B9BBBD;
$text: #333333;
$percentage-light: 20%;
$percentage-lighter: 30%;
$percentage-lightest: 40%;
$percentage-dark: 10%;

:root {
    --primary: #{$primary};
    --primary-light: #{lighten($primary, $percentage-lightest)};
    --primary-dark: #{darken($primary, $percentage-dark)};
    --grey: #{$grey};
    --grey-light: #{lighten($grey, $percentage-light)};
    --grey-lighter: #{lighten($grey, $percentage-lighter)};
    --grey-border: #{$grey-border};
    --text: #{$text};
    --check-color: #1C8C00;
    --alert: #C10000;
}

$tabs-link-active-color: $primary;
$tabs-link-active-border-bottom-color: $primary;