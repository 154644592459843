/* Bulma Components */
@charset "utf-8";

@forward "breadcrumb";
@forward "card";
@forward "dropdown";
@forward "menu";
@forward "message";
@forward "modal";
@forward "navbar";
@forward "pagination";
@forward "panel";
@forward "tabs";
