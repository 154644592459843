/* Bulma Elements */
@charset "utf-8";

@forward "block";
@forward "box";
@forward "button";
@forward "content";
@forward "delete";
@forward "icon";
@forward "image";
@forward "loader";
@forward "notification";
@forward "progress";
@forward "table";
@forward "tag";
@forward "title";
